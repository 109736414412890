.footer-container {
  min-height: 200px;
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr;
  gap: 20px;
  position: relative;
}


.foot p {
  font-family: 'montserrat', sans-serif;
}
.footer-container h2 {
  font-family: 'montserrat', sans-serif;
}
.footer-container p {
  font-family: 'montserrat', sans-serif;
}
.footer-Brands div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px 15px;
}
.footer-Brands div p {
  color: #777;
  font-family: 'montserrat', sans-serif;
}
.footer-contact .infos {
  color: #777;
}
.footer-Location p {
  color: #777;
  width: 80%;
}

.footer-links {
  display: flex;
  width: 100%;
}
.footer-links-mobile {
  display: none;
}
.footer-links a {
  font-family: "IM Fell Great Primer", serif !important;
  color: #777;
}
.footer-links {
  border-bottom: 1px solid #cdcdcd;
}
.social-media-icons a {
  border-radius: 100%;
  background-color: #f2f2f2;
  transition: 0.3s ease;
  cursor: pointer;
}
.social-media-icons a:hover {
  background-color: #000;
  color: #fff !important;
}
.footer-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer-logo-container {
  width: 300px;
  height: 150px;
}
@media screen and (max-width: 600px) {
  .footer-container {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .footer-links {
    flex-direction: column;
    gap: 30px;
  }
  .footer-desc {
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
    gap: 30px;
  }
  .footer-desc p {
    text-align: center;
  }
  /* .footer-links-mobile {
    display: flex;
  } */
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
  }
}
