.blog-details {
  min-height: 450px;
  background-color: #d9d9d9;
}
.blog-details-features {
  margin: auto;
}
.blog-details h1 {
  font-family: "montserrat", sans-serif;
}
.main-title{
  font-family: "montserrat", sans-serif;
  font-weight: bold;
}
.blog-details-container {
  margin: auto;
  width: 80%;
}
.blog-details-container p {
  font-family: "montserrat", serif;
}
.blog-details-container span {
  font-family: "montserrat", serif;
}
.feature {
  width: 260px;
}
@media screen and (max-width: 993px) {
  .blog-details-container h1.title {
    text-align: center !important;
    font-size: 35px !important;
    margin-bottom: 40px !important;
  }
  .feature {
    width: 100% !important;
  }
  .blog-details-container {
    width: 100%;
  }
}


.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines you want to display */
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}