.blog-post-container {
  min-height: 400px;
  background-color: #000;
  overflow: hidden;
}
.blog-post-container h2 {
  font-family: "montserrat", serif;
  color: #fff;
}
.blog-post {
  width: 80%;
  margin: auto;
}
.blog {
  width: 200px;
}
.blog p {
  color: #fff;
  font-family: "montserrat", serif;
}
.blog .blog-source-btn {
  color: #fff !important;
}
.blog-source-btn {
  position: relative;
}
.blog-source-btn::before {
  position: absolute;
  content: "";
  left: -23px;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
  height: 1px;
  background-color: #fff !important;
}
.blogs {
  justify-content: space-between;
}
@media screen and (max-width: 660px) {
  .blogs {
    justify-content: center;
    width: 100%;
  }
  .blogs .blog {
    width: 300px;
  }
}
