
.sell-your-car {
  position: relative;
  height: 300px;
}

.sell-your-car-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: grayscale(30%);
}

.sell-your-car-inputs-container {
  width: 100%;
  min-height: 450px;
  background: #eee;
  border-radius: 30px;
}

.sell-your-car-container{
  background-color : #eeeeee
}

.sell-your-car-container .stock-container {
  overflow: hidden; /* Ensures the zoomed-in image does not overflow the container */
}

/* CSS for the stock image */
.sell-your-car-container .stock-container .stock-img {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container width-wise */
  height: auto; /* Maintain aspect ratio */
}

/* CSS for the hover effect on the stock image */
.sell-your-car-container .stock-container:hover .stock-img {
  transform: scale(1.1); /* Zoom in effect, adjust scale value as needed */
}

.sell-your-car-container h1 {
  font-family: "IM Fell Great Primer", serif !important;
}
.sub-btn {
  font-size: small; /* making the font size small */
  color: black; /* setting the text color to black */
  background-color: transparent; /* ensuring background is transparent */
  border: 1px solid black; /* adding a black border */
  padding: 5px 10px; /* adjusting padding for better appearance */
}

.sell-car-ul li {
  font-family: "IM Fell Great Primer", serif !important;
}
.sell-your-car-container .header ul li {
  color: #000;
  text-shadow: none;
}
.sell-your-car-container .ul-mobile a {
  color: #fff;
  text-shadow: none;
}
.sell-your-car-container .header-humb-icon {
  color: #000;
}
.inputs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  justify-content: center;
  gap: 20px;
  padding: 5% 10%;
  background: #fff;
  /* Creative gradient background */
}

.sell-car-inputs {
  background: #ffffff; /* Light background to contrast with the form */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interaction */
}

.sell-car-inputs:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 6px 12px rgba(0,0,0,0.15); /* Enhanced shadow on hover for depth */
}

.sell-car-inputs p {
  color: #333; /* Darker text for better readability */
  margin-bottom: 10px; /* Spacing */
}

.inputs-container input,
.inputs-container select,
.inputs-container textarea {
  width: 100%;
  border: 2px solid #000; /* Thicker border with theme color */
  outline: none;
  border-radius: 4px;
  padding: 10px; /* Increased padding for better text visibility */
  transition: border-color 0.3s ease; /* Smooth transition for border color change */
}

.inputs-container input:focus,
.inputs-container select:focus,
.inputs-container textarea:focus {
  border-color: #FC6736; /* Highlight focus with a different color */
}

.inputs-container input::placeholder,
.inputs-container textarea::placeholder {
  color: #aaa; /* Lighter placeholder text */
}

.sell-your-car-inputs-container button {
  color: #fff;
  background-color: #000; /* Bright button color for action */
  border: none;
  outline: none;
  width: 100%; /* Full width button */
  padding: 15px 0; /* Increased padding for better clickability */
  border-radius: 4px; /* Rounded corners for the button */
  cursor: pointer; /* Cursor change to indicate clickable */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.sell-your-car-inputs-container button:hover {
  background-color: #369cb8; /* Darker shade on hover for feedback */
}

@media screen and (max-width: 600px) {
  .inputs-container {
    grid-template-columns: 1fr;
    padding: 5%; /* Adjust padding for smaller screens */
  }
  .sell-your-car-image{ 
    height:450px;
    position: absolute;
    width: 100%;
    object-fit:cover;
    z-index: -1;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .inputs-container {
    grid-template-columns: 1fr 1fr; /* Adjust grid for medium screens */
    gap: 10px;
  }
  .inputs-container {
    grid-template-columns: 1fr;
    padding: 5%; /* Adjust padding for smaller screens */
  }
  .sell-your-car-image{ 
    height:450px;
    position: absolute;
    width: 100%;
    object-fit:cover;
    z-index: -1;
  }
}

