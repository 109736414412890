.About-us-container h2 {
  font-family: "montserrat", sans-serif !important;
}
.About-us-container p {
  font-family: "montserrat", serif !important;
}
.about-us-img {
  position: relative;
  height: 300px;
}

.about-us-img .about-us-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: grayscale(30%);
}

/* Container holding the image within the About Us section for overflow handling */
.About-us-container .about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image does not overflow the container */
}

/* CSS for the image within the About Us section */
.About-us-container .about-us-img .about-us-image {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}

/* CSS for the hover effect within the About Us section */
.About-us-container .about-us-img:hover .about-us-image {
  transform: scale(1.1); /* Zoom in effect, adjust scale value as needed */
}


.about-us-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.about-us-blog-img {
  height: 350px;
  filter: grayscale(100%);
}
.about-us-blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.about-us-blog-desc p {
  color: #777;
}
.about-us-achivments {
  width: 100%;
  min-height: 250px;
  background-color: black;
}
.about-us-achivments-numbers {
  color: #fff;
}
.about-us-achivments-numbers p {
  color: #b6bbc4;
}
.about-us-achivments-container {
  height: 100%;
  padding-top: 80px;
}
.About-us-container .stock-img {
  filter: grayscale(90%);
}
.About-us-container .stock-container h2 {
  color: #b6bbc4;
}
.About-us-container .stock-container button {
  border: none;
  outline: none;
  border: 2px solid #fff;
  background: #000;
  color: #b6bbc4;
  border-radius: 40px;
}
.p-flex {
  align-items: center;
}
@media screen and (max-width: 993px) {
  .about-us-container-desc {
    gap: 50px;
  }
}
@media screen and (max-width: 600px) {
  .about-us-achivments-container {
    flex-direction: column;
    padding: 10px;
    gap: 30px;
  }
  .p-flex {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
  }
  .About-us-container .about-us-img .about-us-image { 
    height: 460px;
  }
  
}
@media screen and (min-width: 601px) and (max-width: 850px) {
  .about-us-achivments-container {
    flex-direction: column;
    padding: 10px;
    gap: 30px;
  }
  .p-flex {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
  }
  .About-us-container .about-us-img .about-us-image { 
    height: 460px;
  }
 
}


.body-content{ 
  margin-top:3.5rem;

  margin-left:7rem;
  margin-right:7rem;

}
