.our-cars-container h2 {
  font-family: "montserrat", serif;
}
.our-cars-container {
  min-height: 600px;
  background-color: #d9d9d9;
}
.our-cars-videos {
  height: 650px;
}

.our-cars-videos div {
  height: 100%;
  width: 100%;
}
.space{ 
  width:20px !important;
}
@media screen and (max-width: 993px) {
  .our-cars-videos {
    width: 100%;
    flex-direction: column;
  }
}
