.home {
  position: relative;
  height: 100vh;
  width: 100%;
}
.home .header {
  position: absolute !important;
  z-index: 9999;
}
.home .header ul li {
  transition: 0.3s ease;
}
.home .header ul a:hover li {
  color: #d9d9d9;
}
