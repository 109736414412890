.Profile-swiper {
  height: 300px;
  overflow: visible !important;
}
.profile-container {
  width: 650px;
  margin: auto;
  overflow: visible;
}
.profile-slide {
  border: 2px solid #ff0000;
  border-radius: 6px;
}
.profile-logo {
  height: 70px;
  width: 70px;
  border-radius: 100%;
}
.profile-logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.profile-reviews {
  color: #f29d38;
}
.profile-infos {
  padding: 40px 0 0 40px;
}
.profile-desc {
  padding: 40px 0 0 40px;
}
@media screen and (max-width: 693px) {
  .Profile-swiper .swiper-slide {
    width: 300px;
    height: auto;
  }
  .profile-container {
    width: auto;
  }
  .profile-infos {
    padding: 20px 0 0 20px;
  }
  .profile-desc {
    padding: 20px 0 0 20px;
  }
    .profile-desc p {
      font-size: 14px;
    }
}
