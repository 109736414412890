.reviews-img {
  width: 150px;
  height: 40px;
  margin: auto;
}
.reviews-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.star {
  color: #f29d38;
}
.check-reviews {
  font-size: 13px;
  text-decoration: underline !important;
}
