

.services-container .head-container .header ul li {
  color: #fff;
  text-shadow: none;
}
.services-container .head-container .ul-mobile a {
  color: #fff;
  text-shadow: none;
}
.services-container .header-humb-icon {
  color: #000;
}
.services-grid h2 {
  font-family: "montserrat", sans-serif;
}
.services-grid a {
  font-style: italic;
}
.supperlative-services h2 {
  font-family: "montserrat", sans-serif;
}
.super-services-container h1 {
  font-family: "montserrat", serif;
}
.services-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.services-grid .service {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.services-grid .service .services-img-icon {
  width: 45px;
  height: 45px;
}
.services-grid .service .services-img-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-grid .service p {
  color: #777;
}
.service {
  position: relative;
  border: 1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.services-img-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.more-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
}



.more-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.more-btn span {
  margin-right: 5px;
}

.service:hover .services-img-icon img {
  transform: scale(1.1); /* Enlarge the image on hover */
}

.service-content {
  padding: 20px;
}
/* Ensure that existing CSS styles are maintained */

.supperlative-services {
  width: 100%;
  min-height: 400px;
  background-color: #f8f9fa;
  display:none;
}

.super-services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.service {
  background-color: #fff;
  border: 1px solid #dee2e6; /* Light border */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.services-img-icon {
  width: 80px; /* Adjust the size of service icons */
  height: 80px; /* Adjust the size of service icons */
  object-fit: cover;
}

.more-btn {
  background-color: #343a40; /* Dark button background color */
  color: #fff; /* Button text color */
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.more-btn:hover {
  background-color: #23272b; /* Darker button background color on hover */
}

.more-btn span {
  margin-right: 5px;
}


.text-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* Limits the text to 4 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.stock-container {
  position: relative;
  overflow: hidden;
}

.stock-img {
  width: 100%;
  height: auto;
  transition: transform 0.4s ease;
}

.stock-container:hover .stock-img {
  transform: scale(1.05); /* Enlarge image on hover */
}

.stock-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  padding: 20px;
  transform: translateY(100%);
  transition: transform 0.4s ease;
}

.stock-container:hover .stock-content {
  transform: translateY(0); /* Slide up container on hover */
}

.stock-container:hover .stock-heading {
  color: #4CAF50; /* Change text color on hover */
}

.stock-container:hover .stock-description {
  color: #666; /* Change text color on hover */
}

.services-container .head-container .services-us-img .services-us-image{ 
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */

}

@media screen and (max-width: 600px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  .About-us-container .services-us-img .about-us-image { 
    height: 460px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .services-grid {
    grid-template-columns: 1fr 1fr;
  }
  .About-us-container .services-us-img .services-us-image { 
    height: 460px !important;
  }
}
.supperlative-services {
  background-color: #f8f9fa; /* Light grey background */
  color: #212529; /* Dark grey text for contrast */
}

.supperlative-services h2 {
  text-align: center;
  margin-bottom: 2rem; /* 32px */
}

.super-services-container > div {
  background-color: white; /* White background for each service */
  box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Subtle shadow for depth */
  border-radius: .5rem; /* 8px border radius for rounded corners */
}

.super-services-container .fs-1 {
  color: #007bff; /* Bootstrap primary color for icons */
}

.super-services-container h1 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}
.supperlative-services .section-title {
  text-align: center;
  color: #333; /* Dark grey for emphasis */
}

.supperlative-services .section-subtitle {
  text-align: center;
  color: #666; /* Lighter grey for contrast */
  max-width: 80%;
  margin: 0 auto; /* Center subtitle */
}
.super-services-container .service-item {
  animation: fadeInUpwards 0.8s ease-out forwards;
  opacity: 0; /* Start items as invisible before animation starts */
}

/* Individual animation delays */
.super-services-container .service-item:nth-child(1) {
  animation-delay: 0.2s;
}

.super-services-container .service-item:nth-child(2) {
  animation-delay: 0.4s;
}

.super-services-container .service-item:nth-child(3) {
  animation-delay: 0.6s;
}


.icon-container {
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.service-icon {
  font-size: 3rem; /* Larger icons */
  color: #007bff; /* Icon color - consider your theme */
}

.service-description {
  flex-grow: 1; /* Allow description to take up remaining space */
}

.service-title {
  color: #007bff; /* Title color to match icons */
  margin-bottom: 0.5rem; /* Tighten spacing between title and text */
}

.service-description p {
  color: #555; /* Subdued text color */
  margin: 0; /* Remove default margins for alignment */
}



@keyframes fadeInUpwards {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px; /* Adjust gap if needed */
  border: none; /* Remove border if you prefer */
  background-color: transparent; /* Or any color */
  cursor: pointer;
  font-weight: bold; /* Make text bold */
  text-decoration: none; /* Remove underline from links if inside a <Link> */
  color: inherit; /* Or specific color */
  padding: 0px 10px; /* Adjust padding */
  border-radius: 4px; /* Optional: if you want rounded corners */
  transition: background-color 0.2s; /* Smooth background color change on hover */
}

.more-btn:hover {
  background-color: #f0f0f0; /* Light grey background on hover, adjust as needed */
}
.close-btn {
  background-color: #000; /* Red color background */
  color: white; /* White text color */
  padding: 10px 20px; /* Top and Bottom padding 10px, Left and Right padding 20px */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-weight: bold; /* Bold font */
  margin-top: 20px; /* Margin top for spacing */
  transition: background-color 0.2s; /* Smooth background color transition on hover */
}

.close-btn:hover {
  background-color: #d32f2f; /* Slightly darker red on hover */
}
