.Blog-container .header ul li {
  color: #fff;
  text-shadow: none;
}
.Blog-container .header-humb-icon {
  color: #fff;
}
.blog-serach-inp {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}


/* Container holding the image within the Blog section for overflow handling */
.Blog-container .about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image does not overflow the container */
}

/* CSS for the image within the Blog section */
.Blog-container .about-us-img .about-us-image {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}

/* CSS for the hover effect within the Blog section */
.Blog-container .about-us-img:hover .about-us-image {
  transform: scale(1.1); /* Zoom in effect, adjust scale value as needed */
}

.blog-serach-inp input {
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
}
.Blog-container .blog-s-c {
  background-color: #f2f5fb;
  padding: 10px;
}
.blog-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.blog-grid-img {
  width: 100%;
  height: 260px;
  overflow: hidden;
}
.blog-grid-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transition: 0.3s ease;
}
.blog-grid-feature {
  background-color: #fff;
  transition: 0.3s ease;
  border: 1px solid rgba(136, 138, 139, 0.637);
  border-radius: 8px;
}
.blog-grid-feature:hover {
  border: 1px solid #000;
}
.blog-grid-feature:hover .blog-grid-img img {
  transform: scale(1.5);
  border-radius: 8px 8px 0 0;
}
.date {
  font-size: 14px;
  color: #95989d;
}
.blog-grid-feature {
}
.blog-grid-feature .p-desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; 
  color: #95989d;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px; /* Adjust based on your font-size and line-height */
  width: 100%;
  position: relative;
}
.read-more-btn {
  color: #fff !important;
  background-color: #888a8b;
  border-radius: 8px;
}

.cars-news {
  border: 1px solid rgba(136, 138, 139, 0.342);
  border-radius: 8px;
}

.img-C {
  width: 110px;
  height: 110px;
  border-radius: 8px;
}
.img-C img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.read-more-link {
  text-decoration: underline !important;
  color: grey !important;
}
.recent-posts-container {
  width: 100%;
}
.blog-infos-C {
  border: 1px solid rgba(136, 138, 139, 0.342);
  border-radius: 8px;
}
.blog-infos-C p {
  color: #95989d;
}

@media screen and (max-width: 850px) {
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .Blog-container .about-us-img .about-us-image {
    height: 460px; /* Maintain aspect ratio */
  }
  .Blog-container .about-us-img {
    overflow: hidden; 
    height:460px;
  }
}
