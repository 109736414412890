.instagram-container {
  background-color: #000;
  min-height: 400px;
  overflow: hidden;
}
.instagram-container h3 {
  font-family: "monteserat";
  color: #fff;
}
.instagram-posts {
  justify-content: space-between;
}
.instagram-posts .post {
  height: 250px;
  width: 160px;
  border-radius: 8px;
}
.instagram-posts .post img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.instagram-container {
  position: relative;
}
.instagram-bg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  width: 100%;
  gap: 80px;
}
.instagram-posts{
  position: sticky;
  z-index: 2;
}
.instagram-bg h3 {
  color: #ffffff62;
}
@media screen and (max-width: 687px) {
  .instagram-posts {
    justify-content: center !important;
    gap: 20px;
  }
  .instagram-container h3 {
    font-size: 35px !important;
  }
}
