.Contact-us-container .header ul li {
  color: #fff;
  text-shadow: none;
}
.Contact-us-container .header .header-humb-icon {
  color: #000;
}
.Contact-us-container .stock-img {
  filter: grayscale(80%) blur(2px);
}


/* Container holding the image within the Contact Us section for overflow handling */
.Contact-us-container .about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image does not overflow the container */
}

/* CSS for the image within the Contact Us section */
.Contact-us-container .about-us-img .about-us-image {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}

/* CSS for the hover effect within the Contact Us section */
.Contact-us-container .about-us-img:hover .about-us-image {
  transform: scale(1.1); /* Zoom in effect, adjust scale value as needed */
}

.contact-us-from {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-top: 2px solid #000;
}
.contact-us-from input {
  outline: none;
  border: 1px solid #dedede;
  width: 100%;
}
.contact-us-from textarea {
  outline: none;
  border: 1px solid #dedede;
  width: 100%;
  height: 150px;
}
.Contact-us-container h2 {
  font-family: 'montserrat', sans-serif;
}
.Contact-us-container p {
  font-family: 'montserrat', sans-serif;
}
form button {
  background-color: #000;
  color: #fff;
  outline: none;
  font-size: 18px;
}
.Contact-Info {
  border-top: 2px solid #000;
}
@media screen and (max-width: 768px) {
  .contact-us-from {
    grid-template-columns: 1fr;
  }

  /* CSS for the image within the Contact Us section */
.Contact-us-container .about-us-img .about-us-image {
 height: 460px;
}
.Contact-us-container .about-us-img {
  height: 460px;
}
  
}

