.achievements-container {
  min-height: 550px;
  background-color: #d9d9d9;
}
.achievements-container h3 {
  font-family: "montserrat", sans-serif;
  font-size: 35px;
}
.achivments-container {
  width: 80%;
  margin: auto;
}
.achivments-container ul li {
  font-family: "montserrat", serif;
  font-size: 16px;
  font-weight: 300 !important;
}
.achievements-container h5 {
  font-family: "montserrat", serif;
}
.achivments-number {
  font-family: "montserrat", serif;
}
.achivments-number div {
  width: 250px;
}
.achivments-number div h2 {
  font-family: "montserrat", serif;
}
.achivments-number div p {
  font-family: "montserrat", serif;
  font-weight: 300 !important;

}
.achivments-number {
  justify-content: space-between;
}
@media screen and (max-width: 966px) {
  .achivments-container {
    width: 100%;
  }
  .achivments-number {
    justify-content: center !important;
  }
}
