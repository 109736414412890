.cars-for-sale-container .header ul a li {
  color: #fff;
  text-shadow: none;
  transition: 0.3s ease;
}
.cars-for-sale-container .header ul a:hover li {
  color: #d9d9d9;
}
.cars-for-sale-container .header .header-humb-icon {
  color: #000;
}
.head-container {
  width: 100%;
  position: relative;
  z-index: 999;
}

.head-container .ul-mobile a li {
  color: #fff !important;
}


/* Container holding the image */
.about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image parts outside this container are not visible */
 
  
}


/* CSS for the image */
.about-us-img .about-us-image {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  display: block; /* Ensures the image takes up the width of its container */
  width: auto; /* Keep original width */
  max-width: 100%; /* Ensure it doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the image within the container if it's not already */
}

/* CSS for the hover effect */
.about-us-img:hover .about-us-image {
  transform: scale(1.1); /* Zoom in effect */
}


@media screen and (max-width: 480px) {
  .result-container {
    grid-template-columns: 1fr;
  }
  
/* CSS for the image */
.about-us-img .about-us-image {
  height:460px !important;
}

.about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image parts outside this container are not visible */
  height:460px !important;

  
}

 
}