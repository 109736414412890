.stock-container {
  width: 100%;
  min-height: 150px;
  position: relative;
}
.stock-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.stock-container h2 {
  color: #fff;
  font-family: "montserrat", sans-serif !important;
}
.stock-container p {
  color: #fff;
  font-family: "montserrat", sans-serif !important;
  font-size: 14px !important;
}
.stock-container .container-xl {
  position: sticky;
  z-index: 1;
}
