.header {
  width: 100%;
  height: 90px;
}
.header ul {
  height: 100%;
}
.header ul li {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20),
    0px 0px 10px rgba(0, 0, 0, 0.6); /* Adjusted y-offset to 2px */
  color: #fff;
  font-size: 18px;
}

.header-container {
  height: 100%;
}
.logo-container {
  height: 100%;
  width: 180px;
}
.header-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
}
.header-humb-icon {
  color: #fff;
  cursor: pointer;
}

.ul-mobile {
  position: absolute;
  left: -100%;
  top: 100%;
  background-color: black;
  width: 100%;
  height: auto !important;
  transition: 0.3s ease;
}
.ul-mobile.active {
  left: 0;
}
.ul-mobile a {
  margin: 10px 0 !important;
  transition: 0.3s ease;
}
.ul-mobile a:hover {
  padding-left: 10px;
}


.header ul li:hover {
  color: #ccc; 
  transform: scale(1.05); 
  transition: color 0.3s ease, transform 0.3s ease; 
}

.ul-mobile li:hover {
  color: #ccc; 
  transform: scale(1.05);
  transition: color 0.3s ease, transform 0.3s ease;
}


.header ul a:hover, .ul-mobile a:hover {
  padding-left: 15px; 
  transition: padding-left 0.3s ease; 
}
