.partners-container {
  min-height: 300px;
  overflow: hidden;
  background-color: #000;
}
.partners-container h6 {
  color: #fff;
  font-family: "montserrat", sans-serif;
}
.scroll {
  width: 100%;
  color: #fff;
}
.scroll div {
  white-space: nowrap;
  animation: animate 30s linear infinite forwards;
  animation-delay: -30s;
}
.scroll div:nth-child(2) {
  white-space: nowrap;
  animation: animate2 30s linear infinite forwards;
  animation-delay: -15s;
}
.scroll div h1 {
  font-family: "montserrat", serif;
}
.partners-container {
  position: relative;
}
@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
