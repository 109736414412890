

body {
    font-family: 'Roboto', sans-serif;
  }
  

  :root {
    --cursor-color: orange; /* Adjust the color value as needed */
  }


  .custom-cursor {
    z-index: 100 !important; /* Ensures it has the highest stacking order */
  }
  
  