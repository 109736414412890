.Swpier-C {
  position: absolute;
  width: 100%;
  height: 100%;
}
.swip {
  position: relative;
}
.swiper-pagination {
  left: 0 !important;
  margin-left: 10px;
}
.swip-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.swip:nth-child(1) {
  background-image: "";
}
.swip h1 {
  color: #fff;
  width: 550px;
  margin-top: 150px;
  text-shadow: 2px 2px 1px #000000;
}
.Swpier-C .swiper-pagination-bullet {
  color: #fff;
  background-color: #fff;
  opacity: 1;
  transition: 0.3s ease;
}
.Swpier-C
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: absolute;
  left: 0 !important;
  width: auto;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}
.Swpier-C .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fbb500;
  transform: scale(1.4);
}
@media screen and (max-width: 768px) {
  .swip h1 {
    width: auto;
    font-size: 30px !important;
  }
}
