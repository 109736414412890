@color-primary: #ef5285;
@border-size: 3px;

.search-inp-container {
  width: 450px;
  margin: auto;
}
.search-inp-container .search-inp {
  border: 1px solid #bcbcbc;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: relative;
}
.search-inp div {
  width: 50%;
}
.search-inp input {
  border: none;
  outline: none;
  padding: 0 10px 10px 0;
  font-size: 14px;
  color: #999999;
  width: 100%;
}
.new-arrival-infos p {
  font-size: 0.55rem;
}
.search-inp input {
  width: 100%;
}
.search-inp input::placeholder {
  color: #999999;
}
.Search-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d42929;
  width: 60px !important;
  height: 60px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-out, background-color 0.3s ease-out; /* Smooth transitions for transform and background color */
}
.Search-icon:hover {
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.4);
}
.search-car {
  position: relative;
}
.search-car::before {
  content: "";
  position: absolute;
  right: 0;
  height: 80%;
  width: 2px;
  background-color: #bcbcbc;
}
.search-car-text {
  font-size: 14px;
}
.filter-container {
  width: 25%;
}
.filter-container h3 {
  font-family: 'montserrat', sans-serif;
}
.filter-container input {
  width: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  font-family: "montserrat", serif;
  font-size: 14px;
  outline: none;
}
.filter-container select {
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  font-family: "montserrat", serif;
  font-size: 12px;
  outline: none;
}
.result-container {
  width: 75%;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  gap: 10px;
}
.Slider {
  width: 80%;
}
.slider-filter h4 {
  align-self: flex-start;
}
.search-btn {
  width: 100%;
  background-color: #000; /* Initial background color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Adjust padding as needed */
  font-size: 16px; /* Button text size */
  cursor: pointer; /* Cursor indication for clickable element */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions for effects */
  display: inline-block; /* For alignment and spacing */
  margin-top: 1rem; /* Adjust spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0); /* Initial shadow (transparent) */
}
.search-btn:hover, .search-btn:focus {
  background-color: orange; /* Darker shade on hover/focus */
  transform: scale(1.05); /* Slightly increase size */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Add depth with shadow */
  outline: none; /* Remove focus outline */
}
.car-brand-img {
  width: 25px;
  height: 25px;
  background-position: center center;
}
.car-brand-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.car-brand-name {
  font-size: 15px;
}
.result-container .new-arrival-feature {
  width: auto;
}
.result-container .new-arrival-feature h3 {
  font-size: 1rem !important;
}
.result-container .new-arrival-feature h3:nth-child(2) {
  font-size: 11px !important;
}
.result-container {
  padding-left: 15px;
}
.search-container .header-humb-icon {
  color: #000 !important;
}
.search-inp {
  align-items: center;
}
.search-inp {
  margin-bottom: 50px;
}
.custom-sort-select {
  appearance: none; /* Remove default browser styling */
  background-color: #f8f9fa; /* Light background color */
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adequate padding */
  font-size: 16px; /* Font size for readability */
  color: #333; /* Text color */
  cursor: pointer; /* Cursor to indicate it's selectable */
  width: 100%; /* Full width to fit its container */
  border-radius: 8px; /* Rounded corners for a modern look */
}
/* Styling for the parent container to position the custom arrow */
.custom-select-wrapper {
  position: relative;
  display: inline-block; /* Adjust based on your layout needs */
}
/* Custom arrow using pseudo-elements for the wrapper */
.custom-select-wrapper::after {
  content: '▼'; /* Arrow character */
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none; /* Prevents clicking on the arrow directly */
  color: #007bff; /* Arrow color */
}
/* Optional: Custom wrapper to position the pseudo-element arrow correctly */
.select-wrapper {
  position: relative;
  width: auto; /* Adjust based on your layout */
}
/* Apply this class to the <select>'s parent element */
.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  border: solid #007bff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform: translateY(-50%) rotate(45deg);
}
.new-arrival-feature {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}
.new-arrival-feature-img img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.car-details {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.car-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.price {
  font-size: 14px;
}
.car-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.info {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}
.info p {
  margin-left: 5px;
}
.btn-12,
.btn-12 *,
.btn-12 :after,
.btn-12 :before,
.btn-12:after,
.btn-12:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-12 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}
.btn-12:disabled {
  cursor: default;
}
.btn-12:-moz-focusring {
  outline: auto;
}
.btn-12 svg {
  display: block;
  vertical-align: middle;
}
.btn-12 [hidden] {
  display: none;
}
.btn-12 {
  border-radius: 0.2rem;
  border-width: 2px;
  overflow: hidden;
  padding: 0.8rem 3rem;
  position: relative;
}
.btn-12 span {
  mix-blend-mode: difference;
  font-size: 10px;
  font-weight: 400;
}
.btn-12:after,
.btn-12:before {
  background: linear-gradient(
    90deg,
    #fff 25%,
    transparent 0,
    transparent 50%,
    #fff 0,
    #fff 75%,
    transparent 0
  );
  content: "";
  inset: 0;
  position: absolute;
  transform: translateY(var(--progress, 100%));
  transition: transform 0.2s ease;
}
.btn-12:after {
  --progress: -100%;
  background: linear-gradient(
    90deg,
    transparent 0,
    transparent 25%,
    #fff 0,
    #fff 50%,
    transparent 0,
    transparent 75%,
    #fff 0
  );
  z-index: -1;
}
.btn-12:hover:after,
.btn-12:hover:before {
  --progress: 0;
}
.slider-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%; /* Adjust the width as needed */
  margin: 2rem 1rem; /* Centering the slider container */
  gap: 0.7rem; /* Space between each slider */
}
.slider-filter h4 {
  margin: 0;
  padding-left: 0; /* Removing additional padding */
  color: #333; /* Making the text color darker for better readability */
  font-size: 1.2rem; /* Increasing font size for visibility */
}
/* Slider custom styling (assuming you can inject custom CSS for your slider) */
.MuiSlider-thumb {
  background-color: #1976d2; /* Thumb color */
  border: 2px solid white; /* Adding a border to the thumb for better visibility */
}
.MuiSlider-track {
  height: 8px; /* Making the track thicker */
}
.MuiSlider-rail {
  background-color: #bfbfbf; /* Color of the 'unfilled' part of the track */
  height: 8px; /* Making the rail as thick as the track for consistency */
}
.MuiSlider-valueLabel {
  background-color: #1976d2; /* Background color of the label showing the value */
  color: white; /* Text color for the value label */
}
/* Container holding the image to allow for overflow handling */
.services-container .head-container .about-us-img {
  overflow: hidden; /* Ensures the zoomed-in image does not overflow the container */
}
/* CSS for the image */
.services-container .head-container .about-us-img .about-us-image {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}
/* CSS for the hover effect */
.services-container .head-container .about-us-img:hover .about-us-image {
  transform: scale(1.1); /* Zoom in effect, adjust scale value as needed */
}
.custom-select-style {
  appearance: none; /* Remove default browser styling */
  background-color: #ffffff; /* White background color */
  border: 2px solid #007bff; /* Solid border with your theme color */
  padding: 10px 30px 10px 10px; /* Adequate padding for text and faux arrow space */
  font-size: 16px; /* Readable font size */
  color: #333333; /* Text color */
  cursor: pointer; /* Indicates the element is interactive */
  width: 100%; /* Full width to fit its container */
  border-radius: 5px; /* Rounded corners for a softer look */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
.custom-select-container {
  width: 100%;
  max-width: 500px; /* Increased width */
  margin: 0 auto;
  position: relative;
  font-family: Arial, sans-serif;
}
.custom-select-style {
  width: 100%;
  padding: 15px 20px; /* Increased padding for larger appearance */
  border-radius: 30px; /* More rounded corners */
  border: 2px solid #ced4da; /* Thicker border */
  font-size: 20px; /* Larger font size */
  color: #495057;
  appearance: none; /* Remove default browser styles */
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path fill="%23595F61" d="M287.93 197.286l-14.274-14.273-120.651 120.65 14.275 14.276z"/><path fill="%23595F61" d="M273.655 197.285L4.853 197.285 4.853 167.011 273.655 167.011z"/><path fill="%23595F61" d="M277.656 82.677l-14.275-14.274-120.65 120.651 14.274 14.274z"/><path fill="%23595F61" d="M273.655 95.077L4.853 95.077 4.853 64.803 273.655 64.803z"/></svg>'); /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjusted for larger padding */
  background-size: 15px; /* Larger background size */
  padding-right: 50px; /* Make space for larger arrow */
}
.custom-select-style:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select-style:disabled {
  background-color: #e9ecef;
}
@media screen and (max-width: 1100px) {
  .cars-brand-container {
    flex-direction: column;
  }
  .filter-resulte-container {
    flex-direction: column;
    gap: 100px;
  }
  .filter-container {
    width: 80%;
    margin: auto;
  }
  .result-container {
    width: 100%;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
  }
  .cars-brand-C {
    text-align: center;
  }
  .cars-brand-container {
    display: flex;
    justify-content: center !important;
  }
}
@media screen and (max-width: 640px) {
  .filter-container {
    width: 100%;
  }
  .search-inp-container {
    width: 100%;
  }
  .search-inp {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    border-radius: 40px 40px 0 0 !important;
  }
  .search-car::before {
    width: 100%;
    height: 1px;
    bottom: -5px;
    left: 0;
  }
  .search-inp div {
    width: 100%;
  }
  .Search-icon {
    top: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0;
    width: 100% !important;
    border-radius: 0px 0px 40px 40px;
    border: 3px solid #d42929;
  }
  .search-inp {
    margin-bottom: 100px !important;
  }
  .selection-inp {
    width: 100%;
  }
  .services-container .head-container .about-us-img {
    height: 460px;
  }
  .services-container .head-container .about-us-img .about-us-image {
    height: 460px;
  }
  .services-container .head-container .about-us-img:hover .about-us-image {
    height: 460px;
  }
}
@media screen and (max-width: 480px) {
  .result-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 481px) and (max-width: 850px) {
  .result-container {
    grid-template-columns: 1fr 1fr;
  }
}
.popup-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -535%;
  background-color: #fff; /* Background color for the popup */
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  z-index: 2;
  width: auto;
  max-width: 350px;
}
.popup-content {
  text-align: center;
}
.close-btn {
  background-color: #000; /* Black background for the button */
  color: #fff; /* White text color */
  padding: 10px;
  border: none;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  border-radius: 8px; /* Optional: Rounded corners for the button */
}
.close-btn h3 {
  margin: 0; /* Remove margin from h3 to align properly within the button */
  font-size: 16px; /* Adjust font size as needed */
}
.whatsapp-btn {
  display: block;
  background-color: #25D366; /* WhatsApp color */
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin: 10px auto 0;
  width: 100%; /* Ensure full width for consistency */
  font-size: 14px; /* Adjust font size for button text */
  cursor: pointer;
}
/* Animation for popup */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
.link-view-button {
  display: inline-block;
  padding: 8px 16px;
  color: white;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 12px;
}
.link-view-button:hover, .link-view-button:focus {
  color: white;
  text-decoration: none;
}
.cta {
  border: none;
  background: none;
  cursor: pointer;
}
.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}
.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}
.cta:hover svg {
  transform: translateX(0);
}
.cta:active svg {
  transform: scale(0.9);
}
.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.custom-search-container {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  margin: 0px;
  transition: border-color 0.3s;
  width: max-content; /* Ensures the container fits its contents */
}
.search-filter {
  padding: 5px 10px;
  border: none;
  outline: none;
  background-color: #f8f8f8;
  font-size: 16px;
  border-right: 2px solid #ccc; /* Separates the dropdown from the input */
}
.search-and-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Maximizes space between the two elements */
  width: 100%; /* Ensures the container takes full width */
}
.custom-search-input {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  transition: background-color 0.3s;
}
.custom-search-icon {
  padding: 10px;
  background: #ccc;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.custom-search-container:hover,
.custom-search-container:focus-within {
  border-color: #007BFF;
}
.custom-search-container:hover .custom-search-icon,
.custom-search-container:focus-within .custom-search-icon {
  background: #007BFF;
  color: white;
}
.custom-search-input:focus {
  background-color: #f0f0f0;
}
.custom-search-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centering */
  border: 3px solid #ccc; /* Increased border thickness */
  border-radius: 30px; /* Rounded edges */
  overflow: hidden;
  transition: border-color 0.3s;
  width: 60%; /* Increased width */
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1); /* Adding a subtle shadow for depth */
}
.filter-dropdown, .additional-filter-dropdown {
  padding: 10px 20px; /* Adjusted padding to match the search input */
  font-size: 16px; /* Adjusted font size for uniformity */
  border: none;
  border-radius: 20px; /* Consistent border-radius */
  background-color: #f8f8f8; /* Consistent background color */
  outline: none;
  -webkit-appearance: none; /* Removes default styling on WebKit browsers */
  -moz-appearance: none; /* Removes default styling on Firefox browsers */
  appearance: none;
  cursor: pointer; /* Hand cursor to indicate dropdown */
  margin-right: 10px; /* Right margin for spacing */
}
.custom-search-input {
  flex: 1;
  padding: 15px 25px; /* Increased padding */
  font-size: 18px; /* Increased font size */
  border: none;
  outline: none;
  transition: background-color 0.3s;
  padding: 10px 20px; /* Uniform padding with dropdowns */
  font-size: 16px; /* Uniform font size */
}
.custom-search-btn {
  padding: 15px; /* Increased padding */
  background: #ccc;
  border: none;
  font-size: 24px; /* Increased icon size */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.5s ease-in-out; 
  padding: 10px 20px; /* Adjust padding to match */
  font-size: 20px; /* Slower animation for pronounced effect */
}
.custom-search-container:hover,
.custom-search-container:focus-within {
  border-color: #FFA500; /* Changed hover border color to orange */
}
.custom-search-container:hover .custom-search-btn,
.custom-search-container:focus-within .custom-search-btn {
  background: #FFA500; /* Changed button hover background to orange */
  color: white;
  transform: scale(1.2); /* More pronounced zoom effect */
}
.custom-search-input:focus {
  background-color: #f0f0f0;
}
.additional-filter-dropdown {
  margin-left: 10px; /* Space from the search input */
  padding: 15px;
  font-size: 16px; /* Slightly smaller font size */
  border: 2px solid #ccc; /* Consistent border with search input */
  border-radius: 40px; /* Rounded corners to match the search container */
  background-color: #f8f8f8; /* Matching background color */
  outline: none;
  -webkit-appearance: none; /* Removes default styling on WebKit browsers */
  -moz-appearance: none; /* Removes default styling on Firefox browsers */
  appearance: none;
  cursor: pointer; /* Changes cursor to indicate clickable */
  font-weight: 600;
}
/* Style adjustments for hover state */
.additional-filter-dropdown:hover {
  border-color: #007BFF; /* Hover effect to match the search input */
  background-color: #ececec; /* Slightly darker background on hover */
}
.slider-filter {
  background-color: #f5f5f5;
  border-radius: 15px; 
  padding: 40px; 
  box-shadow: 0 6px 10px rgba(0,0,0,0.25); 
  width: 98%; 
  margin: 30px auto; 
  font-family: 'montserrat', sans-serif; 
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transition for shadow and background color */
}
.slider-filter:hover {
  background-color: #e6e6e6 !important; /* Slightly darker on hover for a subtle effect */
}
.slider-filter h4 {
  color: #333;
  margin-bottom: 25px; /* More vertical spacing between elements */
  font-size: 20px; /* Significantly larger text */
  font-weight: 700; /* Bold for emphasis */
}
.km-range-display {
  color: #444;
  margin-top: 20px; /* Increased spacing for a cleaner layout */
  font-size: 18px; /* Larger font for easy reading */
  font-weight: bold; /* Bold for clear visibility */
}
.selection-inp {
  width: 100%;
  padding: 15px 20px; /* Increased padding for larger appearance */
  border-radius: 30px; /* More rounded corners */
  border: 2px solid #ced4da; /* Thicker border */
  font-size: 18px; /* Larger font size */
  color: #495057;
  appearance: none; /* Remove default browser styles */
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path fill="%23595F61" d="M287.93 197.286l-14.274-14.273-120.651 120.65 14.275 14.276z"/><path fill="%23595F61" d="M273.655 197.285L4.853 197.285 4.853 167.011 273.655 167.011z"/><path fill="%23595F61" d="M277.656 82.677l-14.275-14.274-120.65 120.651 14.274 14.274z"/><path fill="%23595F61" d="M273.655 95.077L4.853 95.077 4.853 64.803 273.655 64.803z"/></svg>'); /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjusted for larger padding */
  background-size: 15px; /* Larger background size */
  padding-right: 50px; /* Make space for larger arrow */
  margin-top: 15px; /* Adjust if needed */
}
.selection-inp:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.selection-inp:disabled {
  background-color: #e9ecef;
}
.custom-select-style {
  font-size: 1.2rem; /* Adjust the size as needed */
  /* Add other styles here as needed */
}
.contact-options-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: slideUp 0.3s ease-out;
  z-index: 1000;
  width: 280px; /* Adjust the width as needed */
}
.contact-info {
  text-align: center;
  margin-bottom: 15px;
}
.contact-title {
  margin: 0;
  color: #333;
  font-weight: bold;
}
.contact-name {
  margin: 5px 0;
  color: #555;
}
.operational-hours {
  margin: 0;
  color: #777;
  font-size: 0.9rem;
}
.contact-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  border-radius: 25px; /* Rounded buttons */
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  transition: transform 0.2s ease-out;
}
.phone-call-button {
  background-color: #007bff; /* Adjust to match your theme */
}
.whatsapp-button {
  background-color: #25D366;
}
.contact-option:hover {
  transform: scale(1.05); /* Slightly enlarge buttons on hover */
}
.contact-icon {
  font-size: 24px;
}
@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.button-text-call {
  color: #fff;
}
.btn-close {
  padding: 5px 0px 10px 5px !important;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}
.btn-close::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}
.btn-close:hover {
  color: #e8e8e8;
}
.btn-close:hover::before {
  width: 100%;
}
.devider {
  border: 0;
  height: 100%;
  background-image: linear-gradient(to right, transparent, orange, transparent);
  margin: 30px 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.select-container {
  animation: fadeIn 0.5s ease-out forwards;
}
.new-arrival-btns {
  display: flex;
  justify-content: space-between;
}
.btn-interest {
  flex: 2;
  height: 60px; /* Adjust height as needed */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}
.link-view-button {
  flex: 1;
 
  color: #000 !important;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}
.btn-interest:hover, .btn-interest:focus, .link-view-button:hover, .link-view-button:focus {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  outline: none;
}
