
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'montserrat', sans-serif;

  text-decoration: none !important;
}
a {
  color: #000 !important;
}
body{
  overflow-x: hidden;
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/futur.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaBold';
  src: url('./fonts/FuturaBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/FuturaLight.ttf') format('truetype');
  font-weight: 300; /* Lighter weight */
  font-style: normal;
}
